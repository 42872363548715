import { ErrorBoundary, Provider as RollbarProvider } from "@rollbar/react";
import { navigate } from "gatsby";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { RecoilRoot } from "recoil";

// eslint-disable-next-line unused-imports/no-unused-imports, no-unused-vars
import * as styles from "./src/application.scss";
import AnalyticsRoot from "./src/core/application/analytics-root";
import { getPreviousPath } from "./src/utils/browser-features";
import { getExperimentsList, setupExperiments } from "./src/utils/experiments";
import { rollbar } from "./src/utils/rollbar";

const ErrorPage = () => navigate("/500");

const RECAPTCHA_SITE_KEY = process.env.GATSBY_RECAPTCHA_SITE_KEY;

export const onRouteUpdate = () => {
  const experimentsList = getExperimentsList();
  const previousPath = getPreviousPath();

  window.analytics &&
    window.analytics.page({
      path: window.location.pathname,
      url: window.location.href,
      previousPath,
      ...{ experimentsList },
    });
};

export const wrapRootElement = ({ element }) => {
  setupExperiments();

  return (
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
      <RollbarProvider instance={rollbar}>
        <ErrorBoundary fallbackUI={ErrorPage}>
          <RecoilRoot>
            <AnalyticsRoot />
            {element}
          </RecoilRoot>
        </ErrorBoundary>
      </RollbarProvider>
    </GoogleReCaptchaProvider>
  );
};

// Workaround for a Google Chrome bug (https://bugs.chromium.org/p/chromium/issues/detail?id=872770)
// It might cause a slight performance hit. See:
// https://github.com/facebook/react/issues/11538#issuecomment-417504600

if (typeof Node === "function" && Node.prototype) {
  const originalRemoveChild = Node.prototype.removeChild;
  Node.prototype.removeChild = function (child) {
    if (child.parentNode !== this) {
      if (console) {
        // eslint-disable-next-line no-console
        console.warn("Cannot remove a child from a different parent", child, this);
      }

      return child;
    }

    // eslint-disable-next-line prefer-rest-params
    return originalRemoveChild.apply(this, arguments);
  };

  const originalInsertBefore = Node.prototype.insertBefore;
  Node.prototype.insertBefore = function (newNode, referenceNode) {
    if (referenceNode && referenceNode.parentNode !== this) {
      if (console) {
        // eslint-disable-next-line no-console
        console.warn(
          "Cannot insert before a reference node from a different parent",
          referenceNode,
          this
        );
      }

      return newNode;
    }

    // eslint-disable-next-line prefer-rest-params
    return originalInsertBefore.apply(this, arguments);
  };
}

import { emitter } from "@marvelapp/react-ab-test";

export const ENABLED = "Enabled";
export const DISABLED = "Disabled";

export interface Experiment {
  name: string;
  variants: Record<string, string>;
  defaultVariant: string;
  weight: [number, number];
}

export function createExperiment(
  name: string,
  defaultVariant: string = ENABLED,
  weight: [number, number] = [50, 50]
): Experiment {
  return {
    name,
    variants: { enabled: ENABLED, disabled: DISABLED },
    defaultVariant,
    weight,
  };
}

export const experiments: Record<string, Experiment> = {
  alternativeOnboardingEmailTitle: createExperiment("Alternative Onboarding Email Title"),
  productComparisonAlternativeCta: createExperiment("Product Comparison Alternative CTA"),
  alternativeHeroBulletPoints: createExperiment("Alternative Hero Bullet Points"),
  glOptional: createExperiment("GL Optional"),
  allowCustomCheckoutSession: createExperiment("Allow Custom Checkout Session"),
  howKnowUsQuestion: createExperiment("How Know Us Question", ENABLED, [100, 0]),
  alternativeHealthProductSelector: createExperiment(
    "New german quote page structure (products above)"
  ),
  phoneNumberInPreQuotes: createExperiment("Phone Number in Pre-Quotes", ENABLED, [100, 0]),
};

export function setupExperiments(experimentsList = experiments): void {
  for (const experimentKey in experimentsList) {
    const experiment: Experiment = experimentsList[experimentKey];
    emitter.defineVariants(experiment.name, Object.values(experiment.variants), experiment.weight);
    emitter.calculateActiveVariant(experiment.name);
  }
}

export const isExperimentDefaultVariant = (experiment: Experiment): boolean =>
  emitter.getActiveVariant(experiment.name) === experiment.defaultVariant;

export const getExperimentVariant = (experiment: Experiment | string): string => {
  const experimentName = typeof experiment === "string" ? experiment : experiment.name;

  return emitter.getActiveVariant(experimentName);
};

export const getExperimentsList = (): Record<string, string> => {
  const experimentsList: Record<string, string> = {};
  const barkibuCardExperiment = emitter.getActiveVariant("Barkibu Card on post quotes");

  for (const experimentKey in experiments) {
    const experiment: Experiment = experiments[experimentKey];

    experimentsList[experiment.name] = emitter.getActiveVariant(experiment.name) as string;
  }

  if (barkibuCardExperiment) {
    return { ...experimentsList, "Barkibu Card on post quotes": barkibuCardExperiment };
  }

  return experimentsList;
};

export const addExperiment = (
  experimentKey: string,
  { name, weight }: { name: string; weight: [number, number] }
): void => {
  const defaultVariant = ENABLED;
  const variants = { enabled: ENABLED, disabled: DISABLED };
  const newExperimentsList: Record<string, Experiment> = {
    ...experiments,
    [experimentKey]: { name, defaultVariant, variants, weight },
  };

  setupExperiments(newExperimentsList);
};

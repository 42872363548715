import { useEffect } from "react";

import { useGetTrackingParams, useSetupAnalytics } from "./analytics-use-cases";

const AnalyticsRoot = (): null => {
  const setupAnalytics = useSetupAnalytics();
  const getTrackingParams = useGetTrackingParams();

  useEffect(() => {
    const initialize = async () => {
      await setupAnalytics;
      getTrackingParams();
    };

    void initialize();
  }, [setupAnalytics, getTrackingParams]);

  return null;
};

export default AnalyticsRoot;
